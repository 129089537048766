import appPackage from '../../package.json'

export default {
  version: appPackage.version,
  baseURL: process.env.BASE_URL ?? 'localhost:8080',
  firebase: {
    apiKey: "AIzaSyB0KW2-mtarAkERKDwGdmnXeBAH1AOdDn8",
    authDomain: "postcards-dev.firebaseapp.com",
    databaseURL: "https://postcards-dev-default-rtdb.firebaseio.com",
    projectId: "postcards-dev",
    storageBucket: "postcards-dev.appspot.com",
    messagingSenderId: "925142544289",
    appId: "1:925142544289:web:3f9103ac553136fdb8a5e0",
    measurementId: "G-TKB7SDJEB5"
  },
}
