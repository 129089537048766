import FFmpegWasmEngine from 'videojs-record/dist/plugins/videojs.record.ffmpeg-wasm.js';

export default {
  install(Vue, options = {}) {
    Vue.prototype.$videorecord = {
      getOptions(canrecord = false) {

        const config = {
          controls: true,
          bigPlayButton: true,
          autoplay: true,
          preload: 'auto',
          loop: false,
          controlBar: {
            deviceButton: false,
            recordToggle: false,
            pipToggle: false,
          },
          width: 500,
          height: 300,
          fluid: true,
          plugins: canrecord
            ? {
                record: {
    //               convertEngine: 'ffmpeg.wasm',
    // convertWorkerURL: '../../node_modules/@ffmpeg/core/dist/ffmpeg-core.js',
    // // convert recorded data to MP4 (and copy over audio data without encoding)
    // convertOptions: ['-c:v', 'libx264', '-preset', 'slow', '-crf', '22', '-c:a', 'copy', '-f', 'mp4'],
    // // specify output mime-type
    // pluginLibraryOptions: {
    //     outputType: 'video/mp4'
    // },
                  pip: false,
                  audio: true,
                  video: true,
                  maxLength: 60,
                  debug: true,
                },
              }
            : {},
        }
        return config
      },
    }
  },
}
